import React, { useEffect } from "react";
import { ANDROID_LINK, IOS_LINK } from "../config";

// Fixed footer component to display in Homepage for download Android and iOS. We can import this module to any pages.
const FixedFooter = () => {
    const [showFooter, setShowFooter] = React.useState(true);
    const [platform, setPlatForm] = React.useState(false);
    useEffect(() => {
        const isIOS = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(window.navigator.platform);
          setPlatForm(isIOS)
    }, []);
    return (
        <React.Fragment>
            {showFooter && <div className="position-fixed d-md-none d-block fixed-footer">
                <div className="d-flex flex-row justify-content-between align-items-center px-2 h-100">
                    <p className="mb-0">
                        Download our app for best experience
                    </p>
                    <div className="d-flex flex-row justify-content-between align-items-center px-2 h-100">
                        <a href={platform ? IOS_LINK : ANDROID_LINK} className="btn btn-primary btn-sm">
                            Download
                        </a>
                        <button className="btn btn-outline-light rounded-lg ms-2 btn-sm" onClick={() => setShowFooter(false)}>
                           <i className="bi bi-x"/>
                        </button>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    )
}

export default FixedFooter;