import Head from 'next/head';
import React from 'react';
// import Footer from '../src/components/footer';
import { connect } from 'react-redux';
import { getPageMetaData, getSaltKeyStatic, logout, switchMode, getFooterLabelsStatic, getSaltKey, getRefreshToken, saveEventsData, getBannerData,  getPagesStatic, getPagesStaticApp } from '../src/actions/actions';
import FixedFooter from '../src/common/fixed-footer';
import CONFIG, { FOOTER, HEADER, LOGO_URL, PAGES } from '../src/config';

import dynamic from 'next/dynamic';

const HomePage = dynamic(import('../src/containers/homepage'), { ssr: false });
const Header = dynamic(import('../src/components/header'), { ssr: false });
const Footer = dynamic(import('../src/components/footer'), { ssr: false });

const { initialPage, pageLimit } = PAGES.HOMEPAGE;

class Index extends React.Component {
    componentDidMount() {
        // Adding class based obn redux value
        if(this.props.darkTheme) {
            document.body.classList.add("dark_theme_bg");
        } else {
            document.body.classList.add("light_theme_bg");
        }
        window.addEventListener("scroll", this.handleScroll);
        
    }
    handleScroll = () => {
        // var myNav = document.getElementById("navSec");
        // if(myNav) {
        //     if (document.documentElement.scrollTop > (window.innerHeight/1.5)) {
        //         myNav.style.backgroundColor = "#080e2c";
        //         myNav.style.backgroundImage = "none";
        //     } else {
        //         myNav.style.backgroundImage = "linear-gradient(180deg, #111212, rgba(15,15,28,.45) 59%,rgba(15,15,28,0))";
        //         myNav.style.backgroundColor = "transparent"
        //     }
        // }
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll, true)
    }
    render() {
        const { isAuth, user, logout, meta_data, darkTheme, switchMode, popularSearch, pages, pagename } = this.props;
        const { meta_title, meta_description, meta_keyword } = meta_data;
        return (
            <>
                <Head>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <title>{meta_title}</title>
                    <meta name="description" content={meta_description}/>
                    <meta name="keywords" content={meta_keyword}/>
                    <meta name="description" content={meta_description}/>
                    <meta property="og:url" content={HEADER.url}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={meta_title}/>
                    <meta property="og:keywords" content={meta_keyword}/>
                    <meta property="og:site_name" content={CONFIG.SITE_URL}></meta>
                    <meta property="og:image" content={LOGO_URL}></meta>
                    <meta property="og:description" content={meta_description}></meta>
                    <link rel="canonical" href={process.env.HOST} />
                </Head>
                <div className={darkTheme ? "dark_theme" : "light_theme"}>
                    <Header user={user} isAuth={isAuth} logout={logout} darkTheme={darkTheme} switchMode={switchMode} pages={pages}/>
                    <div className="col-12 my-2 text-start d-none">
                        <h1 className="header-title">Indian TV Channels</h1>
                    </div>
                        {/* Index page component - passing dark theme which is globally initialized */}
                        <HomePage 
                            darkTheme={this.props.darkTheme} 
                            pagename={pagename}
                            pageId={this.props.pageId}
                            page_url={this.props.pageUrl}
                            bannerData={this.props?.data?.parameters.length > 0 ? this.props?.data?.parameters[0] : false} 
                            // labelsStatic={labels} // Passing server side rendered data list
                            // labelsCountStatic={labelsCount}
                        />
                    <Footer
                        popularSearch={popularSearch}
                        showFooter={true}
                        pages={pages}
                    />
                </div>
                <FixedFooter/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.AppReducer.user, // If user logged you will get the user object
        isAuth: state.AppReducer.isAuth, // isAuth is used to detect if user is logged in or not
        metaData: state.AppReducer.metaData,
        darkTheme: state.AppReducer.darkTheme,
        labels: state.AppReducer.labels
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        switchMode: (theme) => dispatch(switchMode(theme)), // Switch theme
        getSaltKey: (data) => dispatch(getSaltKey(data)),
        getRefreshToken: (data) => dispatch(getRefreshToken(data)),
        saveEventsData: (data) => dispatch(saveEventsData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);


// Initial page - This is default function from Next.js to load data when page is hitting from browser - SSR(Single Side Rendering);

export async function getServerSideProps() {
    // Get saltkey for Index page (root page).
    let headerVal = await getSaltKeyStatic({ 
        p_id: process.env.P_ID, os_type_id: process.env.OS_TYPE_ID, pkey: process.env.P_KEY 
    });

    let headers = {
        Authorization: headerVal.token,
        cid: headerVal.cid,
        oauthtoken: headerVal.access_token,
        pid: process.env.P_ID
    }

    let allPages = await getPagesStaticApp({}, headers);
    
    let pages = allPages.rows.map(page => {
        page.url = page.page_name.split(" ").join("-").toLowerCase();
        page.name = page.page_name;
        page.id = page.page_id;
        return page;
    });

    let defaultHomePage = pages.filter(c => c.url == "home")[0];

    let data = {
        pid: process.env.P_ID,
        pageid: defaultHomePage.id,
        page: initialPage,
        limit: pageLimit,
    }


    // Get page meta data for Index page (root page).
    let res = await getPageMetaData(data, headers);

    let pageData = {
        pid: process.env.P_ID,
        pageid: defaultHomePage.id
    }

    // let pageRes = await getPageData(pageData);
    let pageRes = await getBannerData(pageData, headers);

    // await updatePageDataJSON(pageData, headers);
    
    // Get footer labels for Index page (root page).
    let footerData = await getFooterLabelsStatic({
        pid: process.env.P_ID,
        authority: process.env.AUTHORITY,
        pageid: defaultHomePage.id,
        pagesize: FOOTER.pageLimit
    }, headers);
    
    let meta = {
        meta_title: "Home", meta_description: "Home", meta_keyword: "Home"
    }

    return {
        props: {
            meta_data: res.data.parameters ? res.data.parameters.page_meta_data : meta,
            pagename: defaultHomePage.name,
            pageId: defaultHomePage.id,
            pageUrl: defaultHomePage.url,
            data: pageRes.data,
            popularSearch: footerData
        }
    }
}